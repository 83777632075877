<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style="text-align:right">
        <el-button @click="back" type="primary">返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="tabPosition" type="card" @tab-click="handleClick">
        <el-tab-pane label="概览" name="parent">
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="child">
        </el-tab-pane>
      </el-tabs>
			<overview v-if="tabPosition == 'parent'" :detailList="detailList"></overview>
			<details-info v-if="tabPosition == 'child'" :detailList="detailList"></details-info>
    </div>
  </div>
</template>
<script>
import overview from "./overview";
import detailsInfo from "./detailsInfo";
  export default {
    name: "hello",
    data() {
      return {
            tabPosition: 'parent',
            detailList: {}
			};
    },
    filters: {},
    methods: {
      // 返回
      back() {
        this.$router.go(-1);
      },
			handleClick(tab, event) {
				this.tabPosition = tab.name;
      },
      // 详情
      getDetailList() {
        this.$axios.get("/acb/2.0/parkFiling/getById/" +  this.$route.query.parkFilingId, {
        }).then(res => {
          if (res.state == 0) {
          res.value.propertyPeople =  res.value.propertyPeople || ''
          this.detailList = res.value;
          this.detailList.city = res.value.city.split("-")[1]
          this.detailList.area = res.value.area.split("-")[1]
          this.detailList.street = res.value.street.split("-")[1]
          // 进口
          this.detailList.parkFilingEntryList_title = [];
          if (res.value.parkFilingEntryList.length > 0) {
            this.detailList.parkFilingEntryList.forEach((v, index) => {
              v.entryStatus = v.entryStatus == 0 ? '关闭' : '开放'
              this.detailList.parkFilingEntryList_title.push(
                [{
                    name: "入口" + (index + 1) + "编号",
                    key: "entryCode",
                  },
                  {
                    name: "入口" + (index + 1) + "经度",
                    key: "entryLatitude",
                  },
                  {
                    name: "入口" + (index + 1) + "纬度",
                    key: "entryLongitude",
                  },
                  {
                    name: "入口" + (index + 1) + "状态",
                    key: "entryStatus",
                  }]
              )
            });
          }
          // 出口
          this.detailList.parkFilingExitList_title = [];
          if (res.value.parkFilingExitList.length > 0) {
            this.detailList.parkFilingExitList.forEach((v, index) => {
              v.entryStatus = v.entryStatus == 0 ? '关闭' : '开放'
              this.detailList.parkFilingExitList_title.push(
                [{
                    name: "出口" + (index + 1) + "编号",
                    key: "entryCode",
                  },
                  {
                    name: "出口" + (index + 1) + "经度",
                    key: "entryLatitude",
                  },
                  {
                    name: "出口" + (index + 1) + "纬度",
                    key: "entryLongitude",
                  },
                  {
                    name: "出口" + (index + 1) + "状态",
                    key: "entryStatus",
                  }]
              )
            });
          }
          this.detailList.employeeNum = res.value.parkFilingUserList.length ? res.value.parkFilingUserList.length : ''
          this.detailList.businessTime = res.value.businessTimeBegin + '-' + res.value.businessTimeEnd
          let imgs = res.value.images ?? [];
          if (imgs.length > 0) {
            this.detailList['parkNames'] = imgs.find(v => {
              return v.type === 50 
            })['url']
          }
        } else {
          this.$alert(res.desc);
        }
      });
      }
    },
    components: {
			overview,
			detailsInfo
		},
    created() {},
    mounted() {
      this.getDetailList()
    },
    watch: {}
  };

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
  .breadcrumb {
    height: 35px;
  }

  .content {
    background: #FFFFFF;
    overflow: hidden;
    border-radius: 4px;
    padding: 20px 0;
  }

  .color_1 {
    color: #333333;
  }

  .color_2 {
    color: #D9001B;
  }

</style>
