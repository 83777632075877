var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "breadcrumb" }, [
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.back } },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.tabPosition,
              callback: function ($$v) {
                _vm.tabPosition = $$v
              },
              expression: "tabPosition",
            },
          },
          [
            _c("el-tab-pane", { attrs: { label: "概览", name: "parent" } }),
            _c("el-tab-pane", { attrs: { label: "详细信息", name: "child" } }),
          ],
          1
        ),
        _vm.tabPosition == "parent"
          ? _c("overview", { attrs: { detailList: _vm.detailList } })
          : _vm._e(),
        _vm.tabPosition == "child"
          ? _c("details-info", { attrs: { detailList: _vm.detailList } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }