<template>
  <div class="tableWrapper">
    <!-- <info-table :tableData='tableData'></info-table> -->
    <table class="table">
      <tbody>
				<tr>
					<th><i class="require"></i>停车场名称</th>
					<td>{{detailList.parkFilingName}}</td>
					<th><i class="require"></i>停车场编号</th>
					<td>{{detailList.parkFilingCode}}</td>
					<th rowspan="3">停车场照片</th>
					<td rowspan="3"><img v-if="detailList.parkNames" :src="detailList.parkNames" alt="" srcset="" class="parkImageS"></td>
				</tr>
				<tr>
					<th>停车场类型</th>
					<td>{{parkFilingTypeComputed}}</td>
					<th>停车场状态</th>
					<td>{{parkStatus}}</td>
				</tr>
				<tr>
					<th><i class="require"></i>所属行政区</th>
					<td> {{detailList.city}} - {{detailList.area}}</td>
					<th>所属街道</th>
					<td>{{detailList.street}}</td>
				</tr>
				<tr>
					<th><i class="require"></i>详细地址</th>
					<td>{{detailList.address}}</td>
					<th><i class="require"></i>经营企业</th>
					<td>{{detailList.operationName}}</td>
					<th><i class="require"></i>关联平台车场</th>
					<td>{{detailList.parkName}}</td>
				</tr>
				<tr>
					<th><i class="require"></i>总泊位数</th>
					<td>{{detailList.totalBerthNumber}}</td>
					<th><i class="require"></i>经营性泊位数</th>
					<td>{{detailList.operationalBerthNumber}}</td>
					<th>出入口</th>
					<td>{{detailList.entryNumber ? detailList.entryNumber + "进": "" }}{{detailList.exitNumber ? detailList.exitNumber + "出" : ""}}</td>
				</tr>
				<tr>
					<th>停车场负责人</th>
					<td>{{detailList.personInCharge}}</td>
					<th>负责人联系方式</th>
					<td>{{detailList.personInChargePhone}}</td>
					<th>固定电话</th>
					<td>{{detailList.telephone}}</td>
				</tr>
				<tr>
					<th>员工总数</th>
					<td>{{detailList.employeeNum}}</td>
				</tr>
				<tr>
					<th><i class="require"></i>计费规则描述</th>
					<td>
						<textarea class="textArea" disabled rows="10" v-model="detailList.feeDesc">
							<!--白天 8:00~20:00&#10;-->
							<!--首小时： 2元/15分钟&#10;-->
							<!--非首小时：3元/15分钟&#10;-->
							<!--夜间 8:00~20:00&#10;-->
							<!--首小时： 2元/15分钟&#10;-->
							<!--非首小时：3元/15分钟&#10;-->
						</textarea>
					</td>
				</tr>
			</tbody>
    </table>
  </div>
</template>

<script>
  export default {
    components: {
    },
	  props: ['detailList'],
    data() {
      return {
		  parkFilingTypeList: [
			  {
				  code: '1',
				  desc: '公共停车场'
			  },
			  {
				  code: '2',
				  desc: '专用停车场'
			  },
			  {
				  code: '3',
				  desc: 'P+R停车场'
			  },
			  {
				  code: '4',
				  desc: '路侧停车场'
			  }
		  ]
	  }
    },
	computed: {
		parkFilingTypeComputed() {
		 let that = this
        let [obj] = that.detailList.parkFilingType ?  that.parkFilingTypeList.filter(res => {
          return res.code == that.detailList.parkFilingType
        	}) : [{desc: ''}]
         return obj.desc
      	},
		parkStatus() {
			let parkStatusName = ''
			if (this.detailList.parkStatus == 1) {
				parkStatusName =  '生效'
			} else if  (this.detailList.parkStatus == 2) {
				parkStatusName = '下线 '
			} else if  (this.detailList.parkStatus == 3) {
				parkStatusName =  '变更中'
			}
			return parkStatusName
		},
		// getAera() {
		// 	let a = this.detailList.area ?  this.detailList.area.split("-")[1] : ""
		// 	return a
		// },
		// getCity() {
        // 	let a = this.detailList.city ?  this.detailList.city.split("-")[1] : ""
		// 	return a
		// },
		// getStreet() {
		// 	let a = this.detailList.street ?  this.detailList.street.split("-")[1] : ""
		// 	return a
		// }
	},
	created() {
	},
	methods: {
	}
  }

</script>

<style scoped lang="stylus">
 table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  /* background: #ccc */
}
.tableWrapper >>> table td, .tableWrapper >>> table th {
  border: 1px solid #ddd;
	vertical-align: middle;
	text-align: center;
}

table th {
  font-weight: 500;
	height: 30px;
	background-color: #f5f7fa;
	line-height: 30px;
}
.textArea {
	width: 100%!important;
  padding: 0;
  border: none;
}
.require::before {
	font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
  content: "*";
	color: #f56c6c;
	margin-right: 4px;
}
.parkImageS{
	width: 200px;
	height: 150px;
}
</style>
